// normalize CSS across browsers
import './static/styles/normalize.css';
// custom CSS styles
import './static/styles/layout.css';
import { ShopifyProvider, CartProvider } from '@shopify/hydrogen-react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const React = require(`react`);

export const wrapRootElement = ({ element }) => {
  return (
    <ShopifyProvider
      storeDomain={`spiritual-ecommerce.myshopify.com`}
      storefrontToken={`3148b674202ce0b52284522983d13aaa`}
      storefrontApiVersion={`2024-10`}
      countryIsoCode="US"
      languageIsoCode="EN"
    >
      <CartProvider>{element}</CartProvider>
    </ShopifyProvider>
  );
};
